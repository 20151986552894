import Web3 from "web3";
import config from "../lib/config";

export function isAddress(address) {
  var web3 = new Web3(config.rpcUrl);
  var status = web3.utils.isAddress(address);
  return status;
}

export async function getFormatMulticall1(results, name, pos) {
  try {
    console.log(results, "results____________");
    var returnVal =
      results &&
      results.results &&
      results.results[name] &&
      results.results[name].callsReturnContext &&
      results.results[name].callsReturnContext &&
      results.results[name].callsReturnContext[pos] &&
      results.results[name].callsReturnContext[pos].returnValues &&
      results.results[name].callsReturnContext[pos].returnValues
        ? results.results[name].callsReturnContext[pos].returnValues
        : "";
    console.log(returnVal, "returnVal");
    return returnVal;
  } catch (err) {
    return "";
  }
}
