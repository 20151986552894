import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import AOS from "aos";
import "aos/dist/aos.css";
import { Scrollbars } from "react-custom-scrollbars";
import { connection } from "../helper/connection";
import { useSelector } from "react-redux";
import polyfundcontractabi from "../ABI/polyfundcontractabi.json";
import polyfundcontractabiOld from "../ABI/polyfundcontractabiold.json";
import usdtcontractABI from "../ABI/usdtcontractABI.json";
import Web3 from "web3";
import axios from "axios";
import config from "../lib/config";
import syncEach from "sync-each";
import abiDecoder from "abi-decoder";
import { useNavigate } from "react-router-dom";
import moment from "moment";

abiDecoder.addABI(polyfundcontractabi);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home(props) {
  useEffect(() => {
    AOS.init();
  }, []);
  const navigate = useNavigate();
  const walletConnection = useSelector((state) => state.walletConnection);
  const [latesttrx, setlatesttrx] = useState([]);
  const [totalusers, settotalusers] = useState(0);
  const [totalusers1, settotalusers1] = useState(0);
  const [totalpayout, settotalpayout] = useState(0);
  const [hisloading, setHisLoading] = useState(false);

  async function movingnext() {
    if (
      walletConnection &&
      walletConnection.address &&
      walletConnection.address != undefined &&
      walletConnection.address != null &&
      walletConnection.address != ""
    ) {
      if (
        walletConnection.userdetails &&
        walletConnection.userdetails.isExist
      ) {
        navigate("/dashboard");
      } else {
        navigate("/join-now");
      }
    } else {
      navigate("/join-now");
    }
  }

  useEffect(() => {
    getdetails();
  }, [walletConnection.address]);

  useEffect(() => {
    // getrecentactivity();
  }, []);

  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
          "0." +
          "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
          lead +
          decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  }

  async function getdetails() {
    const web3_bsc = new Web3(config.dataUrl); //local
    var polygonContract = new web3_bsc.eth.Contract(
      polyfundcontractabi,
      config.polyfundcontract
    );

    // var polygonContractOld = new web3_bsc.eth.Contract(
    //   polyfundcontractabiOld,
    //   config.polyfundcontractOld
    // );

    var USDTcontract = new web3_bsc.eth.Contract(
      usdtcontractABI,
      config.USDTContractAddress
    );

    let payoutsTot = await polygonContract.methods.totalPayouts().call();
    let totalUsers = await polygonContract.methods
      .getUsersJoinedLast24Hours()
      .call();
    let totalUsers1 = await polygonContract.methods.totalUsers().call();
    settotalusers(totalUsers);
    settotalusers1(totalUsers1);
    settotalpayout(payoutsTot / 1e18);

    // let Tokenbalnce = await USDTcontract.methods
    //   .balanceOf(config.polyfundcontract)
    //   .call();

    // let Tokenbalnce2 = await USDTcontract.methods
    //   .balanceOf(config.polyfundcontractOld)
    //   .call();

    // var bal = (parseInt(Tokenbalnce) + parseInt(Tokenbalnce2)) / config.decimals;
    //   console.log(Tokenbalnce,Tokenbalnce2,bal,"ThiruBal")

    // let totalpayout = await polygonContract.methods.totalPayouts().call();
    // let totalpayout2 = await polygonContractOld.methods.totalPayouts().call();
    // var amountUserReg = (parseInt(totalUsers) - 329) *10;

    // let finalValue = (((parseInt(totalpayout) + parseInt(totalpayout2)) / 1e18) + bal) + amountUserReg - 19202.777787011913;
    // // finalValue = await convert(finalValue);
    // console.log(parseInt(totalpayout) /1e18,parseInt(totalpayout2) /1e18 ,finalValue,bal,"ThiruPayout")
  }

  async function getrecentactivity() {
    try {
      let respData = await axios({
        method: "get",
        url: `${config.apiDataSorted}`
      });
      setHisLoading(true);
      console.log(respData.data.result, "respDatarmuviespData");
      const web3_bsc = new Web3(config.dataUrl); //local
      var polygonContract = new web3_bsc.eth.Contract(
        polyfundcontractabi,
        config.polyfundcontract
      );
      let arrrec = [];
      if (
        respData.data &&
        respData.data.result &&
        respData.data.result.length > 0
      ) {
        syncEach(
          respData.data.result,
          async function (items, next) {
            let trans_hash = await web3_bsc.eth.getTransaction(items.hash);
            if (trans_hash && trans_hash.input) {
              let trans_detail = abiDecoder.decodeMethod(trans_hash.input);

              let userdet = await polygonContract.methods
                .users(items.from)
                .call();

              let date = moment.unix(items.timeStamp).toDate();

              var now = moment(new Date()); //todays date
              var end = moment(date); // another date

              var duration = now.diff(end, "minutes");
              var duration1 = now.diff(end, "hours");

              if (
                trans_detail &&
                trans_detail.name &&
                trans_detail.name == "regUser"
              ) {
                let datas = {
                  address: web3_bsc.utils.toChecksumAddress(items.from),
                  userid: userdet.id,
                  event: trans_detail.name,
                  value: 0,
                  trx: items.hash,
                  min: duration,
                  hours: duration1
                };
                arrrec.push(datas);
                next();
              } else {
                next();
              }
            } else {
              next();
            }
          },
          function (err, transformedItems) {
            console.log(arrrec, "arrrec");
            const filteredPeople = arrrec.filter((item) => item.userid !== "0");
            console.log(filteredPeople.slice(0, 4), "efewfv");
            setlatesttrx(filteredPeople.slice(0, 4));
            setHisLoading(false);
          }
        );
      }
    } catch (err) {
      console.log(err, "=======errrrrrrrrrrrr=======");
    }
  }

  return (
    <div>
      {/* <ScrollToTopOnMount />   */}
      <Navbar />
      <div className="page_header">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 m-auto"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <h1>
                UnityFund.io is a 100% decentralized in DEFI
                <span> WEB 3.0 ECOSYSTEM </span>
              </h1>
              <div className="row">
                <div className="col-lg-7">
                  {/* <p>With 60 USDT Earn Monthly 3600+ USDT</p> */}

                  <p>
                    With 100 USDT Earn upto 2400 USDT Forever with Same Team
                  </p>

                  {/* <p>Daily 6.2$ for 10 Days - 62$ </p> */}
                </div>
              </div>
              {walletConnection?.userdetails?.isExist ? (
                <button className="primary_btn" onClick={() => movingnext()}>
                  Dashboard
                </button>
              ) : (
                <button className="primary_btn" onClick={() => movingnext()}>
                  Join Now
                </button>
              )}
            </div>
            <div
              className="col-lg-6"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <div className="img_box">
                <img
                  src={require("../assets/images/banimg.png")}
                  className="img-fluid"
                />{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main_wrapper">
        <section className="total">
          <div className="container">
            <div className="row">
              <div
                className="col-xl-12 col-xxl-12 mx-auto"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="100"
              >
                <div className="tot_box">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/tb1.png")}
                            className="img-fluid"
                          />{" "}
                        </div>
                        <div>
                          <h3>
                            {totalpayout > 0
                              ? (parseFloat(totalpayout) + 100).toFixed(2)
                              : "--"}
                          </h3>
                          <p>Total Withdrawn (USDT)</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="box right_box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/tb2.png")}
                            className="img-fluid"
                          />{" "}
                        </div>
                        <div>
                          <h3>
                            {Number(totalusers) > 0 ? Number(totalusers) : "--"}
                          </h3>
                          <p>Total Users (Last 24 Hr)</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="box right_box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/tb2.png")}
                            className="img-fluid"
                          />{" "}
                        </div>
                        <div>
                          <h3>
                            {Number(totalusers1) > 0 ? totalusers1 : "--"}
                          </h3>
                          <p>Total Users</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="features">
          <div className="container">
            <div
              className="head pb-5"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <h2 className="h2tag">
                What we can do for you? <span> Features</span>
              </h2>
            </div>
            <div className="row">
              <div
                className="col-lg-4"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="100"
              >
                <div className="box">
                  <div className="img_box">
                    <img
                      src={require("../assets/images/f1.png")}
                      className="img-fluid"
                    />
                  </div>
                  <h6>100% Decentralized</h6>
                  <p>
                    Unityfund.io is based on 100% decentralized audited smart
                    contract on BSC that is not managed by anyone, including its
                    developing team. Your fund is 100% safe between you and the
                    smart contract that nobody else can access fund at all.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="300"
              >
                <div className="box">
                  <div className="img_box">
                    <img
                      src={require("../assets/images/f2.png")}
                      className="img-fluid"
                    />
                  </div>
                  <h6>TRANSPARENCY</h6>
                  <p>
                    You can view all transactions anytime on the smart contract
                    since its creation. Instant deposit and withdrawal, NO
                    waiting for anybody to approve any transactions.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                <div className="box">
                  <div className="img_box">
                    <img
                      src={require("../assets/images/f3.png")}
                      className="img-fluid"
                    />
                  </div>
                  <h6>GLOBAL PROGRAM</h6>
                  <p>
                    Everyone from Globe can join in this platform and bring
                    Money freedom to lives.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="levels">
          <div className="container">
            <div
              className="head pb-3"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <h2 className="h2tag">
                {" "}
                <span> Generation Income</span>
              </h2>
            </div>
            <div
              className="row"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <div className="col-lg-9 mx-auto">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Levels</th>
                        <th>Members</th>
                        <th>USDT</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Level 1</td>
                        <td>3</td>
                        <td>60</td>
                      </tr>
                      <tr>
                        <td>Level 2</td>
                        <td>9</td>
                        <td>180</td>
                      </tr>
                      <tr>
                        <td>Level 3</td>
                        <td>27</td>
                        <td>540</td>
                      </tr>
                      <tr>
                        <td>Level 4</td>
                        <td>81</td>
                        <td>1620</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="platform_activity ">
          <div className="container">
            <div className="head" data-aos="fade-up" data-aos-duration="1000">
              <h2 className="h2tag mb-5">
                <span> Platform Recent Activity</span>
              </h2>
            </div>
            <div className="row">
              <div className="col-lg-9 mx-auto">
                <div
                  className="activity_box"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <Scrollbars style={{ width: "100%", height: 420 }}>
                    {console.log(latesttrx, "latesttrxlatesttrx")}
                    {latesttrx &&
                      latesttrx.length > 0 &&
                      latesttrx.map((item) => (
                        <div className="activity_scroll">
                          <div
                            className="activity_box_flx"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="100"
                          >
                            <div>
                              <div className="flxbox">
                                <div>
                                  <img
                                    src={require("../assets/images/user_prf.png")}
                                    className="img-fluid"
                                    alt="img"
                                  />
                                </div>
                                <div>
                                  <p className="violet">New user joined</p>
                                </div>
                                <div>
                                  <div className="btn">ID {item?.userid}</div>
                                </div>
                              </div>
                            </div>
                            <div className="rightbtn">
                              <p>
                                <span>
                                  {" "}
                                  <a href="#" target="_blank">
                                    <img
                                      src={require("../assets/images/arrow.png")}
                                      className="img-fluid me-2"
                                      alt="img"
                                    />
                                  </a>
                                </span>
                                <span>
                                  {" "}
                                  {item.hours > 0 ? item.hours : item.min}{" "}
                                  {item.hours > 0 ? " Hours" : " Min"}{" "}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    {hisloading && (
                      <svg
                        class="pl"
                        viewBox="0 0 128 128"
                        width="128px"
                        height="128px"
                      >
                        <defs>
                          <linearGradient
                            id="pl-grad"
                            x1="0"
                            y1="0"
                            x2="1"
                            y2="1"
                          >
                            <stop offset="0%" stop-color="#000" />
                            <stop offset="100%" stop-color="#fff" />
                          </linearGradient>
                          <mask id="pl-mask">
                            <rect
                              x="0"
                              y="0"
                              width="128"
                              height="128"
                              fill="url(#pl-grad)"
                            />
                          </mask>
                        </defs>
                        <g
                          stroke-linecap="round"
                          stroke-width="8"
                          stroke-dasharray="32 32"
                        >
                          <g stroke="hsl(193,90%,50%)">
                            <line
                              class="pl__line1"
                              x1="4"
                              y1="48"
                              x2="4"
                              y2="80"
                            />
                            <line
                              class="pl__line2"
                              x1="19"
                              y1="48"
                              x2="19"
                              y2="80"
                            />
                            <line
                              class="pl__line3"
                              x1="34"
                              y1="48"
                              x2="34"
                              y2="80"
                            />
                            <line
                              class="pl__line4"
                              x1="49"
                              y1="48"
                              x2="49"
                              y2="80"
                            />
                            <line
                              class="pl__line5"
                              x1="64"
                              y1="48"
                              x2="64"
                              y2="80"
                            />
                            <g transform="rotate(180,79,64)">
                              <line
                                class="pl__line6"
                                x1="79"
                                y1="48"
                                x2="79"
                                y2="80"
                              />
                            </g>
                            <g transform="rotate(180,94,64)">
                              <line
                                class="pl__line7"
                                x1="94"
                                y1="48"
                                x2="94"
                                y2="80"
                              />
                            </g>
                            <g transform="rotate(180,109,64)">
                              <line
                                class="pl__line8"
                                x1="109"
                                y1="48"
                                x2="109"
                                y2="80"
                              />
                            </g>
                            <g transform="rotate(180,124,64)">
                              <line
                                class="pl__line9"
                                x1="124"
                                y1="48"
                                x2="124"
                                y2="80"
                              />
                            </g>
                          </g>
                          <g stroke="hsl(283,90%,50%)" mask="url(#pl-mask)">
                            <line
                              class="pl__line1"
                              x1="4"
                              y1="48"
                              x2="4"
                              y2="80"
                            />
                            <line
                              class="pl__line2"
                              x1="19"
                              y1="48"
                              x2="19"
                              y2="80"
                            />
                            <line
                              class="pl__line3"
                              x1="34"
                              y1="48"
                              x2="34"
                              y2="80"
                            />
                            <line
                              class="pl__line4"
                              x1="49"
                              y1="48"
                              x2="49"
                              y2="80"
                            />
                            <line
                              class="pl__line5"
                              x1="64"
                              y1="48"
                              x2="64"
                              y2="80"
                            />
                            <g transform="rotate(180,79,64)">
                              <line
                                class="pl__line6"
                                x1="79"
                                y1="48"
                                x2="79"
                                y2="80"
                              />
                            </g>
                            <g transform="rotate(180,94,64)">
                              <line
                                class="pl__line7"
                                x1="94"
                                y1="48"
                                x2="94"
                                y2="80"
                              />
                            </g>
                            <g transform="rotate(180,109,64)">
                              <line
                                class="pl__line8"
                                x1="109"
                                y1="48"
                                x2="109"
                                y2="80"
                              />
                            </g>
                            <g transform="rotate(180,124,64)">
                              <line
                                class="pl__line9"
                                x1="124"
                                y1="48"
                                x2="124"
                                y2="80"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    )}
                  </Scrollbars>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="tree_sec"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="500"
        >
          <div className="container">
            <div className="bg">
              <h2 className="h2tag head pb-5">
                <span>Genealogy Tree</span>
              </h2>
              <div className="row">
                <div
                  className="col-lg-7"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="500"
                >
                  <img
                    src={require("../assets/images/tree.png")}
                    className="img-fluid"
                  />
                </div>
                <div
                  className="col-lg-5 m-auto"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="700"
                >
                  <div className="in_box">
                    <div className="box bdr">
                      <p>Direct Referral</p>
                      <h5>20%</h5>
                    </div>
                    <div className="box">
                      <p>Generation income </p>
                      <h5>20%</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="faq">
          <div className="container">
            <div
              className="head pb-4"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <h2 className="h2tag text-center mb-3">
                <span>FAQ</span>
              </h2>
              <p>
                You can send email with your questions and we'll give your
                answer
              </p>
            </div>
            <div className="row">
              <div className="col-lg-9 mx-auto">
                <div class="accordion" id="accordionExample">
                  <div
                    class="accordion-item"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="100"
                  >
                    <h2 class="accordion-header" id="headingOne">
                      <button class="accordion-button" type="button">
                        What is Unityfund.io?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p className="para">
                          Unity fund is a decentralized networking platform that
                          operates on the BSC blockchain. It offers easy earning
                          opportunities for members.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="accordion-item"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                  >
                    <h2 class="accordion-header" id="headingTwo">
                      <button class="accordion-button collapsed" type="button">
                        What is the Amount required to join UnityFund.io?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p className="para">
                          60 USDT (BEP-20) Is required to register in
                          unityfund.io.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="accordion-item"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="300"
                  >
                    <h2 class="accordion-header" id="headingThree">
                      <button class="accordion-button collapsed" type="button">
                        How many types of total income streams are there with
                        Unityfund.io?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p className="para">
                          There are 3 Types of income in Unity Fund <br />
                          {/* 1. Daily 6.2$ for 10 Days 62$ <br /> */}
                          2. 10% Referral income <br />
                          3. 10% Generation Income from 4 levels
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="accordion-item"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="300"
                  >
                    <h2 class="accordion-header" id="headingThree">
                      <button class="accordion-button collapsed" type="button">
                        What is Generation Income?
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p className="para">
                          Generation income is generated from your downline line
                          in 1:3 Forced Matrix of 10$ from each member joined
                          Under you upto four levels in 3-9-27-81 tree
                          Structure.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="accordion-item"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="300"
                  >
                    <h2 class="accordion-header" id="headingThree">
                      <button class="accordion-button collapsed" type="button">
                        How Can I earn Generation income?
                      </button>
                    </h2>
                    <div
                      id="collapseSeven"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p className="para">
                          After you join unityfund.io and complete 3 direct
                          referrals you will start receiving generation income.
                          Once your downline also completes 3 direct referrals.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="accordion-item"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEight"
                    aria-expanded="false"
                    aria-controls="collapseEight"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="300"
                  >
                    <h2 class="accordion-header" id="headingThree">
                      <button class="accordion-button collapsed" type="button">
                        How much i can earn with Generation Income and how long?
                      </button>
                    </h2>
                    <div
                      id="collapseEight"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p className="para">
                          You can earn upto 1200$ every 10 days with generation
                          income forever from same team under you.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="accordion-item"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsefour"
                    aria-expanded="false"
                    aria-controls="collapsefour"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="400"
                  >
                    <h2 class="accordion-header" id="headingfour">
                      <button class="accordion-button collapsed" type="button">
                        What are the conditions to Withdraw Generation income?
                      </button>
                    </h2>
                    <div
                      id="collapsefour"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingfour"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p className="para">
                          After making 3 direct referrals you will start
                          receiving generation income forever. And to withdraw
                          generation income you have to make 1 direct referrals
                          every 10 days.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="accordion-item"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsefive"
                    aria-expanded="false"
                    aria-controls="collapsefive"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="400"
                  >
                    <h2 class="accordion-header" id="headingfive">
                      <button class="accordion-button collapsed" type="button">
                        What is missed earnings?
                      </button>
                    </h2>
                    <div
                      id="collapsefive"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingfive"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p className="para">
                          If you don’t make 4 direct referrals your generation
                          income will keep missing if members joined under you
                          with spillover.
                          <br />
                          After rejoin if you don’t make 2 referrals your
                          generation income will missed after 10
                          days on every cycle.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  );
}
